// Minimum requeriments
import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import { TextField, Grid, Tooltip, Hidden, Paper } from '@material-ui/core';
import Currency from './Currency';
import { APICurrency, APIQuote } from '../../services/APICurrency.service';
import Typography from '@material-ui/core/Typography';
import info from '../../../content/assets/icons/info.svg';
import error from '../../../content/assets/icons/error.svg';
import useStyles from './Quote.styles';
import { ICurrencyData, IQuoteData } from '../../types';

const NumberFormatCustom = (props: any) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      prefix={'$'}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={'.'}
      decimalSeparator={','}
      // isNumericString
    />
  );
};

const NumberFormatToCustom = (props: any) => {
  const { value, inputRef, onChange, ...other } = props;
  const parsedValue = parseFloat(value);

  return (
    <NumberFormat
      {...other}
      value={isNaN(parsedValue) ? '' : parsedValue}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={'.'}
      decimalSeparator={','}
      // isNumericString
    />
  );
};

const QuoteCommon: React.FC = () => {
  const [currenciesFrom, setCurrenciesFrom] = useState<ICurrencyData[] | null>([]);
  const [currenciesTo, setCurrenciesTo] = useState<ICurrencyData[] | null>([]);
  const firstQuote = true;
  const [valueFrom, setValueFrom] = useState<string | null>(null);
  const [valueTo, setValueTo] = useState<string | null>(null);
  const [exchangeRate, setExchangeRate] = useState<string | null>(null);
  const [estimatedDate, setEstimatedDate] = useState<string | null>(null);
  const [quoteType, setQuoteType] = useState<string | null>(null);
  const [currencyFrom, setCurrencyFrom] = useState<ICurrencyData | null>(null);
  const [currencyTo, setCurrencyTo] = useState<ICurrencyData | null>(null);
  const [messageFromError, setMessageFromError] = useState<string | null>('');
  const [messageFromErrorIsVisible, setMessageFromErrorIsVisible] = useState<boolean | null>(false);
  const [exchangeRateIsVisible, setExchangeRateIsVisible] = React.useState<boolean | null>(false);
  const [estimatedDateIsVisible, setEstimatedDateisVisible] = React.useState<boolean | null>(false);
  const tooltipMessage =
    'Este plazo es referencial. La fecha de llegada de los fondos a destino puede variar debido a los feriados nacionales e internacionales.';
  const getCurrencyList = async () => {
    const dataFrom = [
      { isoCode: 'CL', currency: 'CLP', id: 'CLP', countryName: 'Chile', popular: true },
    ];
    const { data: dataTo } = await APICurrency.get<ICurrencyData[]>('/');
    const mappedDataTo = _.sortBy(
      dataTo.map(({ id, isoCode, currency, popular }) => ({
        id,
        currency,
        isoCode,
        countryName: new Intl.DisplayNames(['es'], {
          type: 'region',
        }).of(isoCode) as string,
        popular,
      })),
      ['popular', 'countryName', 'currency']
    );
    setCurrenciesFrom(dataFrom);
    setCurrenciesTo(mappedDataTo);
  };
  const getQuote = async (
    amount: number,
    quoteType: string,
    countryCode: string,
    currency: string,
    firstQuote = false
  ) => {
    if (!amount || amount <= 0) {
      setMessageFromError('El monto de origen es inferior al permitido');
      setMessageFromErrorIsVisible(true);
      return;
    }
    if (!quoteType || !countryCode) {
      return;
    }
    try {
      const quote = await APIQuote.get<IQuoteData>('/', {
        params: {
          amount: amount,
          quoteType: quoteType,
          originCountry: 'CL',
          originCurrency: 'CLP',
          destinationCountry: countryCode,
          destinationCurrency: currency,
        },
      });
      const data = {
        status: quote.data['status'],
        value: {
          origin: quote.data['value'],
          usd: quote.data['dofRequired'],
        },
        symbol: quote.data['symbol'],
        estimatedDate: quote.data['estimatedDate'],
        exchangeRate: quote.data['exchangeRate'],
      };

      if (data.status === 0) {
        setMessageFromError('El monto de origen es inferior al permitido');
        setMessageFromErrorIsVisible(true);
        setExchangeRateIsVisible(false);
        setEstimatedDateisVisible(false);
      } else if (data) {
        if (!firstQuote && data.status != 0) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'getQuotes',
            quote_type: quoteType,
            destination_country_home: countryCode,
            destination_currency_home: currency,
            destination_amount_home:
              quoteType === 'sell' ? Number(quote.data['value']) : Number(amount),
            origin_country_home: 'CL',
            origin_currency_home: 'CLP',
            origin_amount_home: quoteType === 'sell' ? Number(amount) : Number(quote.data['value']),
          });
          // window.fbq('trackCustom', 'getQuotes', {codigo_pais: countryCode, value: amount});
        }
        if (data.estimatedDate) {
          setEstimatedDate(data.estimatedDate);
          setEstimatedDateisVisible(true);
        }
        setMessageFromErrorIsVisible(false);
        if (quoteType === 'sell') {
          setValueTo(data.value.origin.toString());
        } else if (quoteType === 'buy') {
          setValueFrom(data.value.origin.toString());
        }
        setExchangeRateIsVisible(true);
        setExchangeRate(data.exchangeRate.toString());
      }
    } catch {
      setMessageFromError('Ha ocurrido un error en la cotización');
      setMessageFromErrorIsVisible(true);
    }
  };

  useEffect(() => {
    getCurrencyList();
    currencyDefault();
  }, []);

  const currencyDefault = () => {
    setQuoteType(null);
    const amount = 500000;
    setValueFrom(amount.toString());
    setQuoteType('sell');
    delayedQuote(amount, 'sell', 'US', 'USD', firstQuote);
  };

  const delayedQuote = useCallback(
    _.debounce(
      (
        amount: number,
        quoteType: string,
        countryCode: string,
        currency: string,
        firstQuote: boolean
      ) => getQuote(amount, quoteType, countryCode, currency, firstQuote),
      700
    ),
    []
  );

  const onChangeFrom = (e: any) => {
    setQuoteType(null);
    const amount: number = parseInt(e.target.value);
    setValueFrom(e.target.value);
    if (currencyTo) {
      if (!quoteType || quoteType === 'sell') {
        setQuoteType('sell');
        delayedQuote(amount, 'sell', currencyTo.isoCode, currencyTo.currency, false);
      }
    }
  };
  const onChangeTo = (e: any): void => {
    setQuoteType(null);
    const amount: number = parseInt(e.target.value);
    setValueTo(e.target.value);
    if (currencyTo) {
      if (!quoteType || quoteType === 'buy') {
        setQuoteType('buy');
        delayedQuote(amount, 'buy', currencyTo.isoCode, currencyTo.currency, false);
      }
    }
  };

  const currencyOnChange = (quoteType: string, currency: ICurrencyData) => {
    if (valueFrom) {
      const amount: number = parseInt(valueFrom.toString());
      if (quoteType === 'buy' && currency) {
        setQuoteType('sell');
        getQuote(amount, 'sell', currency.isoCode, currency.currency);
      } else {
        setValueTo('');
      }
    }
  };

  const classes = useStyles();
  return (
    <Grid className={classes.quoteSection} container spacing={0}>
      <Grid className={classes.quoteAmount} item>
        <TextField
          id="amount-from"
          value={valueFrom}
          label="Tú envías:"
          fullWidth
          defaultValue="500000"
          onChange={onChangeFrom}
          InputProps={{
            classes: { input: classes.ammount },
            inputComponent: NumberFormatCustom,
          }}
          InputLabelProps={{
            style: { color: '#296B88', paddingBottom: '5px' },
          }}
          variant="filled"
        />
      </Grid>
      <Grid item className={classes.quoteCurrency}>
        <Currency
          currencies={currenciesFrom}
          setCurrency={setCurrencyFrom}
          quoteType="sell"
          currencyOnChange={currencyOnChange}
        />
      </Grid>
      <Hidden lgUp mdUp xlUp>
        <Grid item xs={12}>
          {exchangeRateIsVisible && !messageFromErrorIsVisible && (
            <Paper className={classes.control} elevation={0}>
              <Grid container wrap="nowrap" spacing={1}>
                <Grid container item xs={12}>
                  <Grid item xs={1}>
                    <img src={info} style={{ paddingTop: 3, display: 'none' }} />
                  </Grid>
                  <Grid item style={{ display: 'flex' }} xs={11} sm={11}>
                    <div
                      style={{
                        margin: 'auto 0',
                        color: '#296B88',
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      Tipo de Cambio:
                    </div>
                    <div
                      style={{
                        margin: 'auto 0',
                        fontWeight: 'bold',
                        color: '#296B88',
                        fontSize: 18,
                        paddingLeft: '20px',
                      }}
                    >
                      *{exchangeRate}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          )}
        </Grid>
        <Grid item xs={12}>
          {estimatedDateIsVisible && !messageFromErrorIsVisible && (
            <Paper className={classes.control} elevation={0}>
              <Grid container wrap="nowrap" spacing={1}>
                <Grid container item xs={12}>
                  <Grid item xs={1}>
                    <Tooltip title={tooltipMessage}>
                      <img src={info} style={{ paddingTop: 3 }} />
                    </Tooltip>
                  </Grid>
                  <Grid item style={{ display: 'flex' }} xs={11} sm={11}>
                    <div
                      style={{
                        margin: 'auto 0',
                        color: '#296B88',
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      Fecha de llegada:
                    </div>
                    <div
                      style={{
                        margin: 'auto 0',
                        fontWeight: 'bold',
                        color: '#296B88',
                        fontSize: 18,
                        paddingLeft: '20px',
                      }}
                    >
                      {estimatedDate}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          )}
        </Grid>
      </Hidden>
      <Grid className={classes.quoteAmount} item>
        <TextField
          id="amount-to"
          value={valueTo}
          label="El destinatario recibe:"
          fullWidth
          defaultValue=""
          onChange={onChangeTo}
          InputProps={{
            classes: { input: classes.ammountTo },
            inputComponent: NumberFormatToCustom,
          }}
          InputLabelProps={{
            style: { color: '#296B88', paddingBottom: '5px' },
          }}
          variant="filled"
        />
      </Grid>
      <Grid item className={classes.quoteCurrency}>
        <Currency
          currencies={currenciesTo}
          setCurrency={setCurrencyTo}
          quoteType="buy"
          currencyOnChange={currencyOnChange}
        />
      </Grid>
      <Grid item xs={12} lg={6} xl={6} md={6}>
        <Hidden smDown>
          {estimatedDateIsVisible && !messageFromErrorIsVisible && (
            <Paper className={classes.quoteInfo} elevation={0}>
              <Grid container wrap="nowrap" spacing={1}>
                <Grid item>
                  <Tooltip title={tooltipMessage}>
                    <img style={{ paddingTop: 6 }} src={info} />
                  </Tooltip>
                </Grid>
                <Grid container item>
                  <Grid item xs={4} lg={4} xl={4} md={5}>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      style={{ color: '#296B88', paddingTop: 9 }}
                    >
                      Fecha de llegada:
                    </Typography>
                  </Grid>
                  <Grid item xs={5} lg={6} xl={6} md={5}>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      style={{
                        fontWeight: 'bold',
                        color: '#296B88',
                        paddingTop: 6,
                        fontSize: 16,
                      }}
                    >
                      {estimatedDate}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          )}
        </Hidden>
        {messageFromErrorIsVisible && (
          <Paper className={classes.control} elevation={0}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <img src={error} style={{ paddingTop: 3 }} />
              </Grid>
              <Grid container item>
                <Grid>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    style={{ color: '#ff647c', paddingTop: 9 }}
                  >
                    {messageFromError}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} lg={5} xl={5} md={6}>
          {exchangeRateIsVisible && !messageFromErrorIsVisible && (
            <Paper className={classes.quoteInfo} elevation={0}>
              <Grid container wrap="nowrap" spacing={1}>
                <Grid item>
                  <img src={info} style={{ paddingTop: 9, display: 'none' }} />
                </Grid>
                <Grid container item>
                  <Grid item xs={4} lg={4} xl={4} md={5}>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      style={{ color: '#296B88', paddingTop: 9 }}
                    >
                      Tipo de Cambio:
                    </Typography>
                  </Grid>
                  <Grid item xs={5} lg={6} xl={6} md={5}>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      style={{
                        fontWeight: 'bold',
                        color: '#296B88',
                        paddingTop: 6,
                        fontSize: 16,
                      }}
                    >
                      *{exchangeRate}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          )}
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default QuoteCommon;
